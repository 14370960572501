<template>
    <div
        ref="RefModalUserProfile"
        class="modal fade"
        :id="modalId"
        tabindex="-1"
        aria-labelledby="modalUserProfile"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">User profile</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table class="table table-borderless table-sm" border="0">
                        <tbody>
                            <tr>
                                <td width="75px">
                                    <ImgAvatar :key="data.userId" :isUser="data.isUser" :id="data.userId" displayName="" :isVerified="data.isVerified" :isActive="data.userStatus" :privilege="data.privilege" align="left" width="50" height="50" />
                                </td>
                                <td>
                                    <div v-if="data.fullName !== '' && data.displayName !== ''">
                                        <span>{{data.fullName}}</span>
                                        <span class="text-grey"><fa icon="at" class="mx-1"/>{{data.displayName}}</span>
                                    </div>
                                    <div v-else>
                                        <span v-if="data.fullName">{{data.fullName}}</span>
                                        <span v-if="data.displayName">
                                            {{data.displayName}}
                                        </span>
                                    </div>
                                    <div class="lblTiny text-grey pb-2">{{data.userId}}</div>
                                    <div>
                                        Joined <span class="small">{{func.convDateTimeFormat(data.dateJoin, 'text')}}</span> <span class="small fst-italic">({{ func.getDateTimeDiff(func.convDateTimeFormat(data.dateJoin), new Date()) }})</span>
                                    </div>
                                    <div v-if="getPackage !== null">
                                        Work 
                                        <span v-if="getPackage === 'FREE' && data.designation !== '' && data.designation !== null && data.designation !== undefined">
                                            <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                <span>
                                                    as <span class="fw-bold text-grey">{{ func.maskText(data.designation, '') }}</span> 
                                                </span>
                                            </Popper>
                                        </span>
                                        <span v-else-if="getPackage !== 'FREE' && data.designation !== '' && data.designation !== null && data.designation !== undefined">as {{ data.designation }} </span>
                                         in <span>{{ data.jEntity.entityName }}</span> 
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="getPackage === null">
                                <td colspan="2" class="pt-3">
                                    <div class="alert alert-info">
                                        <table width="100%">
                                            <tr>
                                                <td width="57px"><i class="fa-regular fa-user-lock fa-2x"></i></td>
                                                <td><div class="fw-bold">Restricted Information</div>
                                                    <div>
                                                        <span class="isLink" @click="$router.push({ path: '/auth/login' })" data-bs-dismiss="modal"><u>Login</u></span> to view profile
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>

                            <tr v-else>
                                <td colspan="2" class="pt-3">
                                    <div class="row mt-2">
                                        <div class="col bg-light p-2 mx-1" style="border-radius: 5px">
                                            <div class="text-center mb-1"><i class="fa-solid fa-signature fa-lg me-1"></i>
                                                <span v-if="getPackage === 'FREE'">
                                                    <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                        <span class="fw-bold text-grey">{{ func.maskText('25', '') }}</span>
                                                    </Popper>
                                                </span>
                                                <span v-else>25</span>
                                            </div>
                                            <div class="text-center small">Total signature signed</div>
                                        </div>
                                        <div class="col bg-light p-2 mx-1" style="border-radius: 5px">
                                            <div class="text-center mb-1"><i class="fa-solid fa-file-pdf fa-lg me-1"></i>
                                                <span v-if="getPackage === 'FREE'">
                                                    <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                        <span class="fw-bold text-grey">{{ func.maskText('18', '') }}</span>
                                                    </Popper>
                                                </span>
                                                <span v-else>18</span>
                                            </div>
                                            <div class="text-center small">Total doc signed</div>
                                        </div>
                                        <div class="col bg-light p-2 mx-1" style="border-radius: 5px">
                                            <div class="text-center mb-1"><i class="fa-solid fa-folder fa-lg me-1"></i>
                                                <span v-if="getPackage === 'FREE'">
                                                    <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                        <span class="fw-bold text-grey">{{ func.maskText('15', '') }}</span>
                                                    </Popper>
                                                </span>
                                                <span v-else>15</span>
                                            </div>
                                            <div class="text-center small">Total folder signed</div>
                                        </div>
                                    </div>
                                    
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                  
                </div>
                <div class="modal-footer">
                    <button v-if="getPackage === 'FREE'" type="button" class="btn btn-info text-white" @click="$router.push({ path: '/plan' })" data-bs-dismiss="modal">
                        <i class="fa-solid fa-box-check me-2"></i>Upgrade
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Close
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
</template>

/* input required
userId (display avatar purpose), fullName, displayName, isUser, isVerified, userStatus, privilege, dateJoin, designation, entityName, 
totalSignatureSigned, totalDocSigned, totalFolderSigned
*/
<script>
import {ref, inject, onMounted} from 'vue'
import Popper from 'vue3-popper'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'ModalUserProfile',
    components: { ImgAvatar, Popper },
    props: ['modalId', 'userId', 'data' ],
    setup (props) {
        const axios = inject('axios')
        
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { getPackage } = useStore()
        const func = funcs

        const userId = ref(props.userId)

        const data = ref(props.data)

        onMounted(() => {
            // console.info('ModalUserProfile', userId.value)
            // console.info('ModalUserProfile data', JSON.stringify(data.value))
            // data.value.designation = 'Senior Developer'

        })

        return {
            axios, route, router, store, getPackage, func, userId, data
        }
    }
}
</script>
<style>
    
</style>