<template>
    <div
        ref="RefModalEntityProfile"
        class="modal fade"
        :id="modalId"
        tabindex="-1"
        aria-labelledby="modalEntityProfile"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Organization profile</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- <div class="small bg-warning">{{ data }} | {{ data.jLogo.width }}</div> -->
                    <table class="table table-borderless table-sm" border="0">
                        <tbody>
                            <tr>
                                <td :width="(data.jLogo.width*1+10) + 'px'">
                                    <ImgLogo :id="data.jLogo.entityId" :isVerified="data.isVerified" :width="data.jLogo.width" :height="data.jLogo.height" />
                                </td>
                                <td>
                                    <div v-if="data.entityName !== ''">
                                        {{data.entityName}}
                                        <span v-if="getPackage !== null">
                                            <span v-if="getPackage === 'FREE' && data.registrationId !== ''">
                                                <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                    <span class="lblTiny text-grey">({{ func.maskText(data.registrationId, '') }})</span>
                                                </Popper>
                                            </span>
                                            <span v-else-if="data.registrationId !== ''" class="lblTiny text-grey">({{data.registrationId}})</span>
                                        </span>
                                    </div>
                                    <div v-else>
                                        - 
                                        <span v-if="getPackage !== null">
                                            <span v-if="getPackage === 'FREE' && data.registrationId !== ''">
                                                <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                    <span class="lblTiny text-grey">({{ func.maskText(data.registrationId, '') }})</span>
                                                </Popper>
                                            </span>
                                            <span v-else-if="data.registrationId !== ''" class="lblTiny text-grey">({{data.registrationId}})</span>
                                        </span>
                                    </div>

                                    <div v-if="getPackage !== null && data.website" class="pb-2">
                                        <span v-if="getPackage === 'FREE'" >
                                            <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                <span class="lblTiny text-grey">({{ func.maskText(data.website, '') }})</span>
                                            </Popper>
                                        </span>
                                        <span v-else class="lblTiny text-grey"> {{ data.website }}</span>
                                    </div>

                                    <div>
                                        Joined <span class="small">{{func.convDateTimeFormat(data.dateJoin, 'text')}}</span> <span class="small fst-italic">({{ func.getDateTimeDiff(func.convDateTimeFormat(data.dateJoin), new Date()) }})</span>
                                    </div>
                                    
                                </td>
                            </tr>
                            <tr v-if="getPackage === null">
                                <td colspan="2" class="pt-3">
                                    <div class="alert alert-info">
                                        <table width="100%">
                                            <tr>
                                                <td width="57px"><i class="fa-regular fa-house-lock fa-2x"></i></td>
                                                <td><div class="fw-bold">Restricted Information</div>
                                                    <div>
                                                        <span class="isLink" @click="$router.push({ path: '/auth/login' })" data-bs-dismiss="modal"><u>Login</u></span> to view profile
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>

                            <tr v-else>
                                <td colspan="2" class="pt-3">
                                    <div class="row mt-2">
                                        <div class="col bg-light p-2 mx-1" style="border-radius: 5px">
                                            <div class="text-center mb-1"><i class="fa-solid fa-signature fa-lg me-1"></i>
                                                <span v-if="getPackage === 'FREE'">
                                                    <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                        <span class="fw-bold text-grey">{{ func.maskText('25', '') }}</span>
                                                    </Popper>
                                                </span>
                                                <span v-else>25</span>
                                            </div>
                                            <div class="text-center small">Total signature signed</div>
                                        </div>
                                        <div class="col bg-light p-2 mx-1" style="border-radius: 5px">
                                            <div class="text-center mb-1"><i class="fa-solid fa-file-pdf fa-lg me-1"></i>
                                                <span v-if="getPackage === 'FREE'">
                                                    <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                        <span class="fw-bold text-grey">{{ func.maskText('18', '') }}</span>
                                                    </Popper>
                                                </span>
                                                <span v-else>18</span>
                                            </div>
                                            <div class="text-center small">Total doc signed</div>
                                        </div>
                                        <div class="col bg-light p-2 mx-1" style="border-radius: 5px">
                                            <div class="text-center mb-1"><i class="fa-solid fa-folder fa-lg me-1"></i>
                                                <span v-if="getPackage === 'FREE'">
                                                    <Popper class="popperDark" arrow hover placement="top" content="Upgrade to view more information">
                                                        <span class="fw-bold text-grey">{{ func.maskText('15', '') }}</span>
                                                    </Popper>
                                                </span>
                                                <span v-else>15</span>
                                            </div>
                                            <div class="text-center small">Total folder signed</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button v-if="getPackage === 'FREE'" type="button" class="btn btn-info text-white" @click="$router.push({ path: '/plan' })" data-bs-dismiss="modal">
                        <i class="fa-solid fa-box-check me-2"></i>Upgrade
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Close
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
</template>

<script>
import {ref, inject, onMounted} from 'vue'
import Popper from 'vue3-popper'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import ImgLogo from '@/components/ImgLogo.vue'

/* input required
entityId (show logo purpose), logo width, logo height, isVerified, entityName, registrationId, website, dateJoin,
totalSignatureSigned, totalDocSigned, totalFolderSigned
 */
export default {
    name: 'ModalEntityProfile',
    components: { ImgLogo, Popper },
    props: ['modalId', 'entityId', 'data' ],
    setup (props) {
        const axios = inject('axios')
        
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { getPackage } = useStore()
        const func = funcs

        const entityId = ref(props.entityId)

        const data = ref(props.data)

        onMounted(() => {
            console.info('ModalEntityProfile', entityId.value)
            console.info('ModalEntityProfile data', JSON.stringify(data.value))

            data.value.registrationId = "E1234567"
            data.value.website = "https://signon.my"
            data.value.dateJoin = "0"
        })

        return {
            axios, route, router, store, getPackage, func, entityId, data
        }
    }
}
</script>

<style>
    
</style>